<template>
  <div>
    <div class="my-6 text-start">
    <div>
      <h3 class="font-bold">
        Editar usuário
      </h3>

      <span class="text-sm text-gray-500"
        >Verifique as informações abaixo e clique no botão <b>Salvar</b> para
          confirmar as modificações feitas
      </span>
    </div>
    
  </div>

<div class="flex justify-end my-5">
      <Button @click="edit" color="primary" text="Salvar"></Button>
    </div>

  <div class="card rounded-lg bg-white shadow-sm border p-8">
    <div class="mb-6">
      <span class="text-sm text-gray-500">Informações básicas: </span>
    </div>

    <div class="flex lg:flex-row flex-col items-center">
      <div class="w-full p-2">
        <Input
          v-model="user.usuario"
          type="default"
          label="Usuário"
          placeholder="Usuário"
          :readonly="false"
        />
      </div>
      <div class="w-full p-2 relative">
        <Input
          v-model="user.senha"
          :type="type"
          placeholder="Senha"
          label="Senha"
        />
       <i :class="`gg-${icon} icon`" @click="changeInputType"></i>
      </div>
    </div>
  </div>


  </div>

  
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { GET, PUT, } from "../../services/api";
import { isEmpty } from "../../services/validate";
import { inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "EditarUsuario",
  components: {
    Input,
    Button
  },
   data()
  {
    return{
      isMobile: false
    }
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const loader = inject("loading");
    const alert = inject("alert");
    const modal = inject("modal");
    const user = ref({
      "usuario": "",
      "senha": ""
    });

    const type = ref("password");
    const icon = ref("eye");

    onMounted(async () => {
      loader.open();
      const usertemp = await GET(`usuarios/${route.params.id}`);

      user.value.usuario = usertemp.usuario
      loader.close();
    });

    const edit = async () => {
      const validate = isEmpty(user.value.usuario);
      if (validate) {
        alert.open("Atenção!", validate, "warning");
      } else {
        modal.open(
          "Atenção",
          "Deseja realmente editar esse usuário?",
          "warning",
          "Sim",
          async () => {
            loader.open();
            try {
              const response = await PUT(`usuarios/${route.params.id}`, user.value);
              alert.open(
                "Sucesso!",
                `Usuário ${user.value.usuario} foi editado com sucesso!`,
                "success"
              );
              router.push("/usuarios");
            } catch (e) {
              alert.open("Atenção!", e, "warning");
            }
            loader.close();
          },
          modal.close()
        );
      }
    };

    const changeInputType = () => {
      type.value = type.value == 'password' ? 'default' : 'password';
      icon.value = icon.value == 'eye' ? 'eye-alt' : 'eye';
    };

    return { 
      edit, 
      user,
      changeInputType,
      type,
      icon
    };
  },
};
</script>
<style scoped>
.block{
    display: block;
  }
  
  .icon{
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 38%;
  }

  .btn{
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .mobile{
  height: 100vh;
  position:absolute;
  margin-right: 25px;
  }
</style>